<template>
  <div ref="topDiv">
    <Notice ref="notice" @accepted="noticeAccepted"/>
    <DashboardNavBar/>
    <a ref="downloadLink" target="_blank"></a>
    <b-container fluid class="bg-custom">
      <b-row>
        <b-col class="p-1">
          <div>
            <span class="h1">Worklist</span>
          </div>
          <div>
            <b-navbar>
              <b-badge variant="secondary" show>
                {{ worklist.length }}
                <span class="d-none d-sm-inline">Scans</span>
              </b-badge>
              <b-nav-form class="ml-2" ref="tableControls">
                <b-input-group prepend="Show:" size="sm">
                  <b-form-select v-model="perPage" :options="perPageOptions" size="sm"></b-form-select>
                </b-input-group>
              </b-nav-form>
              <b-navbar-nav v-if="perPage > 0">
                <b-pagination class="ml-2" 
                  v-model="currentPage" 
                  :total-rows="worklist.length" 
                  :per-page="perPage" 
                  align="fill"
                  size="sm"
                  :limit="5"
                  :first-number="true"
                  :last-number="true">
                </b-pagination>              
              </b-navbar-nav>
              <b-navbar-nav>
                <b-button class="ml-2" title="Refresh" @click="syncWorklist(true)" variant="primary" size="sm"><b-icon icon="arrow-clockwise"/></b-button>
                <b-button class="ml-2" v-if="worklist.length > 0" @click="syncWorklist(false)" variant="secondary" size="sm">Remove Unavailable</b-button>
              </b-navbar-nav>
              <b-navbar-nav v-if="importExportAvailable" class="ml-auto">
                <b-button class="ml-2" @click="exportWorklist()" variant="secondary" size="sm">Backup Worklist</b-button>
                <b-button class="ml-2" @click="importWorklist()" variant="secondary" size="sm">Restore Backup</b-button>
              </b-navbar-nav>
            </b-navbar>
            <b-table id="wlTable" ref="wlTable" outlined class="small"
                  no-border-collapse
                  responsive
                  :sticky-header="tableHeight"
                  :items="worklist"
                  sort-by="scanID"
                  sort-desc
                  :current-page="currentPage"
                  :per-page="perPage"
                  :fields="fields"
                  primary-key="scanID">
              <template #cell(scanID)="data">
                ADM{{data.item.scanID.padStart(6, '0')}}
              </template>
              <template #cell(scanData.x00080020)="data">
                {{format_dcm_date(data.item.scanData.x00080020)}}
              </template>
              <template #cell(scanData.x00100010)="data">
                {{format_dcm_name(data.item.scanData.x00100010)}}
              </template>
              <template #cell(scanData.x00100030)="data">
                {{format_dcm_date(data.item.scanData.x00100030)}}
              </template>
              <template #cell(datetime)="data">
                {{ formatSubmitDate(data.item.datetime) }}
              </template>
              <template #cell(state)="data">
                {{data.item.state}}
              </template>
              <template #cell(report)="data">
                  <b-button class="m-0 p-0" title="View Report" @click="handleReport(data.item)" :variant="data.item._rowVariant" v-if="data.item.state=='READY'" href="" target="_blank" size="sm">
                    <b-icon icon="file-pdf" font-scale="2"/>
                  </b-button>
                </template>
              <template #cell(qa_images)="data">
                  <b-button class="m-0 p-0" title="Download DICOM QA images" @click="downloadImages(data.item)" :variant="data.item._rowVariant" v-if="data.item.state=='READY'" href="" target="_blank" size="sm">
                    <b-icon icon="file-zip" font-scale="2"/>
                  </b-button>
              </template>
            </b-table>
          </div>
          <div ref="wlAlerts">
            <div class="alert alert-warning text-center p-1" role="alert">
              <feather type="alert-triangle"></feather>&nbsp;Worklist will be lost if browser data deleted unless backup is available.
            </div>
            <div v-if="!importExportAvailable" class="alert alert-danger text-center p-1" role="alert">
              <feather type="alert-triangle"></feather>&nbsp;This browser does not support file system access. Try using Chrome.
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import DashboardNavBar from './DashboardNavBar.vue'
import Notice from './Notice.vue'
import worklistFns from '../common/worklistFns.js'

export default {
  name: 'worklist',
  components: {
    DashboardNavBar,
    Notice
  },
  data() {
    return {
      currentPage: 1,
      perPageOptions: [
          { value: 2, text: '2' },
          { value: 5, text: '5' },
          { value: 10, text: '10' },
          { value: 15, text: '15' },
          { value: 25, text: '25' },
          { value: 50, text: '50' },
          { value: 100, text: '100' },
          { value: 0, text: 'All' }
      ],
      perPage: 0,
      tableHeight: "200px",
      worklist: []
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()

    if (worklistFns.get_notice_accept_time(this.$store.state.keycloak.tokenParsed.preferred_username) === null) {
      this.$refs.notice.show()
    }
    else {
      this.noticeAccepted()
    }
  },
  computed: {
    fields() {
      return [ 
        { "key": "scanID", "label": "Scan ID", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "scanData.x00100010", "label": "Patient Name", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "scanData.x00100020", "label": "Patient ID", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "scanData.x00100030", "label": "DOB", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "scanData.x00080050", "label": "Accession Number", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "scanData.x00080020", "label": "Study Date", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "scanData.x00080060", "label": "Modality", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "scanData.x00081030", "label": "Description", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "datetime", "label": "Submitted", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "state", "label": "State", "sortable": true, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "report", "label": "Report", "sortable": false, "size": 0, "thClass": "bg-secondary text-white" },
        { "key": "qa_images", "label": "QA", "sortable": false, "size": 0, "thClass": "bg-secondary text-white" }
      ]
    },
    importExportAvailable() {
      return ('showOpenFilePicker' in self) && ('showSaveFilePicker' in self)
    },
    production() {
      return process.env.NODE_ENV === 'production'
    }
  },
  methods: {
    format_dcm_date(dcm_date) {
      try {
        return worklistFns.format_dcm_datetime(dcm_date)
      }
      catch {
        return "N/A"
      }
    },
    format_dcm_name(dcm_name) {
      return worklistFns.format_dcm_name(dcm_name)
    },
    formatSubmitDate(datetime) {
      let date = new Date(datetime)
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`
    },
    async exportWorklist() {
      try {
        let exportHandle = await window.showSaveFilePicker({
          id: "worklistBackup",
          startsIn: "documents",
          suggestedName: "corinsights_worklist.bak"
        })
        // TEST WRITABLE
        const backupFile = await exportHandle.createWritable();
        // Write the contents of the file to the stream.
        await backupFile.write(worklistFns.worklist_backup(this.$store.state.keycloak.tokenParsed.preferred_username));
        // Close the file and write the contents to disk.
        await backupFile.close();
      }
      catch(ex) {
        this.$log.error(`Error exporting worklist to file: ${ex.message}`)
      }
    },
    async importWorklist() {
      try {
        let [importHandle] = await window.showOpenFilePicker({
          id: "worklistBackup",
          startsIn: "documents",
          suggestedName: "corinsights_worklist.bak"
        })
        
        const backupFile = await importHandle.getFile();
        const backup = await backupFile.text();
        await worklistFns.worklist_restore(this.$store.state.keycloak.tokenParsed.preferred_username, backup);
        this.syncWorklist(true);
      }
      catch(ex) {
        this.$log.error(`Error exporting worklist to file: ${ex.message}`)
      }
    },
    syncWorklist(keepUnavailable) {
      this.$log.debug('GET '+this.$configs.endpointsBaseUrl+'/Worklist')
      let opts = {
        headers: {
            Authorization: 'Bearer '+this.$store.state.keycloak.token
        }
      }
      axios.get(this.$configs.endpointsBaseUrl+'/Worklist', opts)
      .then(async(response) => {
        this.worklist = await worklistFns.worklist_sync(this.$store.state.keycloak.tokenParsed.preferred_username, response.data.worklist, keepUnavailable)
      })
      .catch(err => {
        this.$log.error("Error fetching worklist from server: "+err.message)
      })
    },
    noticeAccepted() {
      this.syncWorklist(true);
    },
    handleResize(/*event*/) {
      let wh = (window.outerHeight > window.innerHeight) ? window.innerHeight : window.outerHeight
      let bottomMargin = this.$refs.wlAlerts.getBoundingClientRect().height + 60
      let tableHeight = wh - this.$refs.tableControls.getBoundingClientRect().bottom - bottomMargin
      this.tableHeight = (tableHeight < 200) ? "200px" : `${tableHeight}px`
    },
    handleReport(worklistEntry) {
      this.$log.debug(`Starting report retrieval for scanId=${worklistEntry.scanID}`)
      this.$store.commit('changeReportScan', worklistEntry)
      this.$router.replace('report')
      return
    },
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        solid: true,
        title: 'CorInsights',
        variant: variant,
      })
    },
    downloadImages(worklistEntry) {
      this.$log.debug(`Starting image retrieval for scanId=${worklistEntry.scanID}`)
      const toastMsg = `Requesting QA images for [${this.format_dcm_name(worklistEntry.scanData.x00100010)}] [${this.format_dcm_date(worklistEntry.scanData.x00080020)}]...`
      this.displayToast(toastMsg, 'info')
      let imagesUrl = this.$configs.endpointsBaseUrl+'/Images/' + encodeURIComponent(worklistEntry.scanID)
      let opts = {
        headers: {
            Authorization: 'Bearer '+this.$store.state.keycloak.token
        },
        responseType: 'blob'
      }
      axios.get(imagesUrl, opts)
      .then((response) => {
        const downloadName = `ADM${worklistEntry.scanID.padStart(6, '0')}_images.zip`
        const downloadUrl = URL.createObjectURL(response.data);
        this.$refs.downloadLink.href = downloadUrl
        this.$refs.downloadLink.setAttribute('download', downloadName)
        this.$refs.downloadLink.click()
      })
      .catch(err => {
        this.$log.error("Error fetching images, err=: "+err.message)
      });
      return
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.bg-custom {
  background-color: #d0d6e2;
}
.bg-custom2 {
  background-color: #3b465e
}
/* Fix an issue in vue-bootstrap v2.22.0: https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
</style>
